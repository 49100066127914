import React from "react"
import styled from "styled-components"
import { FormattedMessage } from "gatsby-plugin-intl"

import Layout from "layout/Layout"

const StyledView = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.notFound.background};
  h1 {
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSize.m};
    color: ${({ theme }) => theme.colors.notFound.text};
    letter-spacing: 10px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    @media (max-width: 800px) {
      font-size: ${({ theme }) => theme.fontSize.xs};
    }
  }
`

function NotFoundPage() {
  return (
    <Layout>
      <StyledView>
        <h1>
          <FormattedMessage id="notFound" />
        </h1>
      </StyledView>
    </Layout>
  )
}

export default NotFoundPage
